<template>
  <TranslationsWrapper :languageCode="languageCode" :isLoggedIn="isLoggedIn">
    <GlobalWrapper :languageCode="languageCode" :isLoggedIn="isLoggedIn">
      <slot />
    </GlobalWrapper>
  </TranslationsWrapper>
</template>

<script>
import { defineComponent } from 'vue';
import TranslationsWrapper from '@/components/TranslationsWrapper.vue';
import GlobalWrapper from '@/components/GlobalWrapper.vue';

export default defineComponent({
name: 'ApplicationSettings',
  components: {
    TranslationsWrapper,
    GlobalWrapper,
  },
watch: {
  applicationTheme(val) {
    //set body attribute to visually change the theme
    //this attribute is used in variable.css to set the colors by theme
    document.body.setAttribute('theme', val);
  },
},

data() {
  return {
    applicationTheme: '',
    //get languageCode if already stored or use english by default
    languageCode: localStorage.getItem("language") || 'gb',
    isLoggedIn: localStorage.getItem("token") != null,
    token: localStorage.getItem("token")
  }
},

async created() {  
  this.getApplicationTheme()
},

methods: {
  setApplicationTheme(theme) {
    localStorage.setItem('applicationTheme', theme);
    this.applicationTheme = theme;
  },
  getApplicationTheme() {
    //get stored applicationTheme or return 'light' theme as default if none stored
    this.applicationTheme = localStorage.getItem('applicationTheme') || 'light';
    return this.applicationTheme;
  },

  setLanguageCode(newLanguageCode) {
    this.languageCode = newLanguageCode;
    localStorage.setItem("language", newLanguageCode);
  },
  getLanguageCode() {
    return this.languageCode;
  },

  getIsLoggedIn() {
    return this.isLoggedIn;
  },
  setIsLoggedIn(token) {
    if (token) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.setToken(token);
  },

  getToken() {
    return this.token;
  },
  setToken(token) {
    localStorage.setItem("token", token);
    if (!token) { localStorage.removeItem('token'); } //when logging out
    this.token = token;
  },
},

provide() {
  let appSettings = {};
  //APP SETTINGS
  Object.defineProperty(appSettings, 'languageCode', {
    configurable: true,
    enumerable: true,
    get: () => this.languageCode,
  });
  Object.defineProperty(appSettings, 'applicationTheme', {
    get: () => this.applicationTheme,
  });
  Object.defineProperty(appSettings, 'token', {
    get: () => this.token,
  });
  Object.defineProperty(appSettings, 'isLoggedIn', {
    get: () => this.isLoggedIn,
  });
  return {
    appSettings,
    setApplicationTheme: this.setApplicationTheme,
    getApplicationTheme: this.getApplicationTheme,
    setLanguageCode: this.setLanguageCode,
    getLanguageCode: this.getLanguageCode,
    setIsLoggedIn: this.setIsLoggedIn,
    getToken: this.getToken
  }
}

});
</script>
