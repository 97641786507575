<template>
  <slot />
</template>

<script>
import { defineComponent } from 'vue';

const httpService = require("@/utils/services");

export default defineComponent({
name: 'TranslationsWrapper',
props: [ 'languageCode', 'isLoggedIn' ],
watch: {
  languageCode() {
    if (this.isLoggedIn) {
      this.reloadLoggedInTranslations();
    } else {
      this.reloadLoggedOutTranslations();
    }
  },

  isLoggedIn(val) {
    if (val) {
      this.reloadLoggedInTranslations();
    } else {
      this.reloadLoggedOutTranslations();
    }
  },
},

data() {
  return {
    translationsData: {},
    loggedOutTranslations: [ 'loginTranslation', 'signUpTranslation' ],
    signUpTranslation: null,
    loginTranslation: null,

    loggedInTranslations: [ 
      'globalTranslation', 'addTransactionTranslation', 'categoriesManagementTranslation',
      'allTransactionsTranslation', 'monthsTranslation', 'settingsPageTranslation', 'shoppingListsModuleTranslation',
      'projectTranslation'
    ],
    globalTranslation: null, //Titles of all main views (pages)
    addTransactionTranslation: null, //new transaction elements
    categoriesManagementTranslation: null, //create category form element
    allTransactionsTranslation: null, //transaction modification elements
    monthsTranslation: null, //months' full names
    settingsPageTranslation: null,
    shoppingListsModuleTranslation: null,
    projectTranslation: null,
    
    status: null
  }
},

beforeMount() {  
  if (this.isLoggedIn) {
    this.reloadLoggedInTranslations();
  } else {
    this.reloadLoggedOutTranslations();
  }
  
},

methods: {
  async reloadLoggedInTranslations() {
    //Load translations for all pages accessed when logged in.
    //The paths' names are stored in the loggedInTranslations property of the component.
    await this.loggedInTranslations.forEach(translation => {
      httpService
      .getByParamsNoToken("/translation/" + translation + "/", { languageCode: this.languageCode })
      .then((response) => {
        this[translation] = { ...response };
      })
      .catch((error) => console.error(error));
    });
    await httpService
      .getByParamsNoToken("/status/", { languageCode: this.languageCode })
      .then((response) => {
        this.status = { ...response };
      })
      .catch((error) => console.error(error));
  },

  async reloadLoggedOutTranslations() {  
    //Load translations for all pages accessed when logged out.
    //The paths' names are stored in the loggedOutTranslations property of the component.
    await this.loggedOutTranslations.forEach(translation => {
      httpService
      .createWithNoToken("/translation/" + translation + "/", { languageCode: this.languageCode })
      .then((response) => {
        //Returned response is copied and the copy is assigned to the property
        //that has the same name as the path corresponding to the returned translation.
        this[translation] = { ...response }
      })
      .catch((error) => console.error(error));
    });
  },
},

provide() {

  this.loggedOutTranslations.forEach(translation => {
    Object.defineProperty(this.translationsData, translation, {
      enumerable: true,
      get: () => this[translation],
    });
  });

  this.loggedInTranslations.forEach(translation => {
    Object.defineProperty(this.translationsData, translation, {
      get: () => this[translation],
    });
  });
  
  Object.defineProperty(this.translationsData, 'status', {
    get: () => this.status,
  });

  return {
    translationsData: this.translationsData,
  }
}

});
</script>
