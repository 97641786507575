<script>
import { defineComponent } from 'vue';

import ToastDisplay from '@/mixins/ToastDisplay.vue';
import Swal from 'sweetalert2';
const httpService = require("@/utils/services");

export default defineComponent({
  name: 'ShoppingListModule',
  inject: [ 'getLanguageCode', 'getToken', 'translationsData' ],
  mixins: [ ToastDisplay ],

  data() {
    return {
      shoppingListModule: {},
      recipesInBasket: null,
      recipes: null,
      ingredients: null,
      shoppingLists: null
    }
  },

  watch: {
  },

  computed: {
    translations() {
      return { ...this.translationsData?.shoppingListsModuleTranslation, ...this.translationsData?.globalTranslation };
    }
  },

  created() {
  },

  methods: {  
    async reloadRecipesTables() {
      await this.getRecipes();
      await this.getIngredients();
      await this.getShoppingLists(); 
      await this.getRecipesInBasket(); 
    },

    emptyRecipesTables() {
      this.recipes = null;
      this.ingredients = null;
      this.shoppingLists = null;
      this.recipesInBasket = null;
    },

    setRecipesInBasket(params) {
      if (params.action) {
        //attribute action is not empty
        if (params.action === 'add') {
          //action is to add item in basket
          this.addInBasket(params.recipesInBasket);
        } else if (params.action === 'remove') {
          //action is to remove item from basket
          this.removeFromBasket(params.recipesInBasket);
        } else if (params.action === 'update') {
          this.updateBasket(params.recipesInBasket);
        }
      }
      else {
        //attribute action doesn't exist
        return this.recipesInBasket = params;
      }
    },


    setShoppingLists(params){
      if (params.action) {
        //attribute action is not empty
        if(params.action === 'add'){
          this.addShoppingList(params.shoppingList);
        } else if(params.action === 'delete'){
          this.deleteShoppingList(params.shoppingList);
        }
      }
      else {
        //attribute action doesn't exist
        return this.shoppingLists = params;
      }
    },

    updateBasket(item) {
        
      this.recipesInBasket = {...this.recipesInBasket};
      this.recipesInBasket[item.type + '.' + item.id] = item; 

      localStorage.setItem('basket=' + this.getToken(), JSON.stringify(this.recipesInBasket));
    },

    addInBasket(item) {
      if (!this.recipesInBasket[item.type + '.' + item.id]) {   
        this.recipesInBasket = {...this.recipesInBasket};
        this.recipesInBasket[item.type + '.' + item.id] = item; 

        localStorage.setItem('basket=' + this.getToken(), JSON.stringify(this.recipesInBasket));

        if (item.type === "recipe") { this.recipes[item.id].inBasket = true; }
        else if (item.type === "ingredient") { this.ingredients[item.id].inBasket = true; }
        else { this.shoppingLists[item.id].inBasket = true; }
      }

    },

    removeFromBasket(item) {
      if (this.recipesInBasket[item.type + '.' + item.id]) {   
        this.recipesInBasket = {...this.recipesInBasket};     
        delete this.recipesInBasket[item.type + '.' + item.id];     

        if (Object.keys(JSON.parse(localStorage.getItem('basket=' + this.getToken()))).length === 1) {
          localStorage.setItem('basket=' + this.getToken(), "{}");
        } else {
          localStorage.setItem('basket=' + this.getToken(), JSON.stringify(this.recipesInBasket));
        }  

        if (item.type === "recipe") { this.recipes[item.id].inBasket = false; }
        else if (item.type === "ingredient")  { this.ingredients[item.id].inBasket = false; }  
        else { 
          if (this.shoppingLists[item.id]) { this.shoppingLists[item.id].inBasket = false; }
        }  
      }  
    },

    getRecipesInBasket() {
      //return object if exist already
      if (this.recipesInBasket && this.recipesInBasket.size > 0) {
        return this.recipesInBasket;
      }     

      //this.recipesInBasket is empty

      //create basket variable in localStorage for current user
      //if it doesn't exist already
      if (!localStorage.getItem('basket=' + this.getToken())) {
        localStorage.setItem('basket=' + this.getToken(), "{}");
      } 

      //create this.recipesInBasket object from values stored in localStorage
      this.recipesInBasket = JSON.parse(localStorage.getItem('basket=' + this.getToken()));

      //if items already in basket, their inBasket attribute if changed to true
      //this is done so they are visually appear to be selected
      Object.values(this.recipesInBasket).forEach(item => {
        if (this.recipes && Object.keys(this.recipes).length > 0 && item.type === "recipe") {
          this.recipes[item.id].inBasket = true;
          this.removeFromBasket(item);
          this.addInBasket(this.recipes[item.id]);
        } else if (this.ingredients && Object.keys(this.ingredients).length > 0 && item.type === "ingredient") {
          this.ingredients[item.id].inBasket = true;
          this.removeFromBasket(item);
          this.addInBasket(this.ingredients[item.id]);
        } else if (this.shoppingLists && Object.keys(this.shoppingLists).length > 0 && item.type === "shopping_list") {
          this.shoppingLists[item.id].inBasket = true;
          this.removeFromBasket(item);
          this.addInBasket(this.shoppingLists[item.id]);
        }
      });
    },
    
    getRecipes() {    
      //If no object or empty object, GET request to get all the recipes by months  
      if (!this.recipes || Object.keys(this.recipes).length === 0) {
        httpService.getByParams("/recipes/", { languageCode: this.getLanguageCode() }).then(response => {
          const currentMonth = new Date().getMonth() + 1;  
          //keep only the recipes of the current month  
          this.recipes = response[currentMonth];
          this.getRecipesInBasket();
        })
        .catch((error) => console.error(error));
      }
      //Return object if it's not empty
      return this.recipes;
    },

    getIngredients() {
      //If no object or empty object, GET request to get all the ingredients by months 
      if (!this.ingredients || Object.keys(this.ingredients).length === 0) {
        httpService.getByParams("/ingredients/", { languageCode: this.getLanguageCode() }).then(response => {
          const currentMonth = new Date().getMonth() + 1;  
          //keep only the ingredients of the current month  
          this.ingredients = response[currentMonth];
          this.getRecipesInBasket();
        })
        .catch((error) => console.error(error));
      }
      //Return object if it's not empty
      return this.ingredients;
    },

    getShoppingLists() {
      if (this.shoppingLists) {         
        return this.shoppingLists;
      }
      httpService.getByParams("/shoppinglists/", { languageCode: this.getLanguageCode() }).then(response => {
        this.shoppingLists = response;
        this.getRecipesInBasket();
      })
      .catch((error) => console.error(error));

      return this.shoppingLists;
    },

    deleteShoppingList(shoppingList) {
      if (shoppingList.type !== "shopping_list") { return; }
      Swal.fire({
        titleText: this.translations?.form_deletionConfirm,
        text: this.translations?.form_deletionIrreversibleWarning,
        icon: 'warning',
        showCloseButton: true,
        confirmButtonText: this.translations?.form_delete,
        confirmButtonColor: 'red'
      }).then((result) => {
          if(result.isConfirmed) {
            httpService.handleDelete("/shoppinglists/" + shoppingList.id)
              .then(async res => {  
                // Remove from shoppingLists 
                this.shoppingLists = {...this.shoppingLists};     
                delete this.shoppingLists[res.id]; 
                // Remove from basket
                this.removeFromBasket(res);

                this.queueSuccessToast(this.translations?.shoppinglistDeletionSuccess);
              }).catch(err => {
                console.error(err);
                this.queueErrorToast(this.translations?.shoppinglistDeletionSuccess);
              });
          }
      });
    },

    addShoppingList(shoppingList) {
      if (shoppingList.type !== "shopping_list") { return; }
      let request = {        
        shoppingList: shoppingList,
        languageCode: this.getLanguageCode()
      };  

      httpService.create("/shoppinglists/", request)
        .then((res) => {
          if (!this.shoppingLists[res.id]) {   
            this.shoppingLists = { ...this.shoppingLists, [res.id]: res};
          }
          this.queueSuccessToast(this.translations?.shoppinglistCreationSuccess);
        }).catch(err => {
          console.error(err);
          this.queueErrorToast(this.translations?.shoppinglistCreationError);
        }); 
    }

  },
});
</script>
