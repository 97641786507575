<template>
  <ion-toolbar mode="md">
    <ion-buttons slot="start" v-if="this.getToken() && !modalStyle">
      <ion-menu-button mode="md" auto-hide="false"></ion-menu-button>
    </ion-buttons>
    <ion-title mode="md">{{ title }}</ion-title>
    <ion-buttons slot="end" class="ion-margin-right">
      <ion-button @click="setApplicationTheme('light')" fill="clear" class="ion-margin-right" 
        v-if="getApplicationTheme() === 'dark'">
        <font-awesome-icon icon="moon" size="2x" />
      </ion-button>  
      <ion-button @click="setApplicationTheme('dark')" fill="clear" class="ion-margin-right" 
        v-else>
        <font-awesome-icon icon="sun" size="2x" />
      </ion-button>  
      <ion-button routerLink="/settings" fill="clear" class="ion-margin-right" v-if="this.getToken() && !modalStyle">
        <font-awesome-icon :icon="['fas', 'cog']" size="2x" />
      </ion-button>  
      <ion-button routerLink="/language" fill="clear" class="ion-margin-right" v-if="!this.getToken() && !modalStyle">
        <font-awesome-icon :icon="['fas', 'language']" size="2x" />
      </ion-button>    
      <ion-button @click="$router.back()" fill="clear" class="ion-margin-right" v-if="modalStyle">
        <font-awesome-icon :icon="['fas', 'times']" size="2x" />
      </ion-button>  
    </ion-buttons>
  </ion-toolbar>
</template>

<script>
import { defineComponent } from 'vue';

import { IonToolbar, IonTitle, IonButtons, IonButton, IonMenuButton } from '@ionic/vue';

export default defineComponent({
name: 'TopToolbar',
components: { IonToolbar, IonTitle, IonButtons, IonButton, IonMenuButton },
inject: [ 'getToken', 'setApplicationTheme', 'getApplicationTheme' ],
props: {
  title: {
    type: [String, undefined],
    default: 'Undefined Title'
  },
  modalStyle: {
    type: [Boolean, undefined],
    default: false
  }
},

methods: {
}

});
</script>

<style scoped>
  ion-title {
    margin: 0;
    padding-inline: 0;
  }
</style>