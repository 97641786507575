<script>
import { defineComponent } from 'vue';

export default defineComponent({
name: 'Pages',
inject: [ 'translationsData' ],

data() {
  return {
    selectedPage: this.$route.name,
  }
},

watch: {
  '$route'(to) {
    this.selectedPage = to.name;
  }
},

computed: {
  inv_pages() {
    let translations = this.translationsData.globalTranslation;
    if (!translations || translations.length === 0) { return null; }
    let pages = [
      {
        name: 'Language',
        url: '/language',
        icon: 'home',
        label: translations.navigation_home,
        bottom_tab_display: false
      },
      {
        name: 'Login',
        url: '/login',
        icon: 'user',
        label: 'Login: no translation',
        bottom_tab_display: false
      },
      {
        name: 'Inscription',
        url: '/inscription',
        icon: 'user-plus',
        label: 'Sign up: no translation',
        bottom_tab_display: false
      },
    ];
    return pages;
  },
  acc_pages() {
    let translations = this.translationsData.globalTranslation;
    if (!translations || translations.length === 0) { return null; }
    let pages = [      
      {
        name: 'Settings',
        url: '/settings',
        icon: 'cog',
        label: translations.navigation_setting,
        bottom_tab_display: false
      },
    ];
    return pages;
  },
  nav_pages() {
    let translations = this.translationsData.globalTranslation;
    if (!translations || translations.length === 0) { return null; }
    let pages = [
      {
        name: 'Home',
        url: '/home',
        icon: 'home',
        label: translations.navigation_home,
        bottom_tab_display: true
      },
      {
        name: 'Budget',
        url: '/budget',
        icon: 'piggy-bank',
        label: translations.navigation_budget,
        bottom_tab_display: true
      },
      {
        name: 'Categories',
        url: '/categories',
        icon: 'chart-pie',
        label: translations.navigation_categories,
        params: {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear()
        },
        bottom_tab_display: true
      },
      {
        name: 'BudgetCourse',
        url: '/budgetCourse',
        icon: 'shopping-cart',
        label: translations.navigation_groceries,
        bottom_tab_display: true
      },
      {
        name: 'Transactions',
        url: '/transactions',
        icon: 'plus',
        label: translations.navigation_transaction,
        bottom_tab_display: true
      },
      {
        name: 'RecurringPaiements',
        url: '/recurringPaiements',
        icon: 'file-invoice-dollar',
        label: translations.navigation_recurring,
        bottom_tab_display: true
      },
      {
        name: 'LifeProjects',
        url: '/lifeprojects',
        icon: 'route',
        label: translations.navigation_projects,
        bottom_tab_display: true
      },
      {
        name: 'Recipes',
        url: '/recipes',
        icon: 'utensils',
        label: translations.navigation_recipes,
        bottom_tab_display: true
      },
      {
        name: 'Shopping Lists',
        url: '/shoppinglists',
        icon: 'shopping-basket',
        label: translations.navigation_shoppinglists,
        bottom_tab_display: true
      },
      {
        name: 'Friendship',
        url: '/friendship',
        icon: 'user-friends',
        label: translations.navigation_friend,
        bottom_tab_display: true
      },
    ];
    return pages;
  },
},

methods: {
  redirect(url) {
    this.$router.push(url);
  },

  paramRedirect(page) {
    this.$router.push({
      name: page.name,
      params: page.params,
    });
  },

  handleClickPageChange(page) {
    if (page.params) {
      this.paramRedirect(page);
    } else {
      this.redirect(page.url);
    }
  }
},

});
</script>
