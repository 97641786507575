<template>
    <ion-button :type="type" :shape="shape" :size="size" :expand="expand" :fill="fill"
    @click="handleClickEvent" :color="color" :disabled="disabled">
      {{ buttonLabel }}
    </ion-button>
</template>

<script>
import { defineComponent } from 'vue';

import { IonButton } from '@ionic/vue';

export default defineComponent({
name: 'BaseButton',
components: { IonButton },
emits: ['update:modelValue', 'handleClick'],
props: {
  type: {
    type: String,
    default: 'button'
  },
  buttonLabel: {
    type: String,
    default: "No label error"
  },
  disabled: {
    type: [Boolean, undefined],
    required: false
  },
  size: {
    type: [String, undefined],
    required: false
  },
  shape: {
    type: [String, undefined],
    required: false
  },
  expand: {
    type: [String, undefined],
    required: false
  },
  fill: {
    type: [String, undefined],
    required: false
  },
  clickEventTrue: {
    type: [Boolean, undefined],
    default: false
  },
  color: {
    type: [String, undefined],
    required: false
  }
},

methods: {
  handleClickEvent(event) {
    if (this.clickEventTrue) {
      this.$emit('handleClick', event);
    }
  }
}

});
</script>