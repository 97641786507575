<template>
  <slot />
</template>

<script>
import { defineComponent } from 'vue';
import ShoppingListModule from '@/mixins/ShoppingListModule.vue';
import UserModule from '@/mixins/UserModule.vue';

export default defineComponent({
name: 'GlobalWrapper',
props: [ 'languageCode', 'isLoggedIn' ],
mixins: [ ShoppingListModule, UserModule ],

watch: {

  languageCode() {
    if (this.isLoggedIn) {
      this.emptyRecipesTables();
      this.reloadRecipesTables();
      this.reloadUserSetups();
    }
  },

  isLoggedIn(val) {
    if (val) {
      //reload recipes, ingredients and recipesInBasket if user logged in
      this.reloadRecipesTables();
      this.reloadUserSetups();
    }
  },
},

data() {
  return {
  }
},

created() {
  if (this.isLoggedIn) {
    this.reloadRecipesTables();
    this.reloadUserSetups();
  }
},

methods: {
},

provide() {
  //SHOPPING LIST MODULE SETUP
  Object.defineProperty(this.shoppingListModule, 'recipes', {
    configurable: true,
    enumerable: true,
    get: () => this.recipes,
  });
  Object.defineProperty(this.shoppingListModule, 'ingredients', {
    get: () => this.ingredients,
  });
  Object.defineProperty(this.shoppingListModule, 'shoppingLists', {
    get: () => this.shoppingLists,
    set: (shoppingLists) =>  {
      if (shoppingLists?.action) {        
        this.setShoppingLists(shoppingLists);
      } else {
        this.shoppingLists = shoppingLists
      }
    }
  });
  Object.defineProperty(this.shoppingListModule, 'recipesInBasket', {
    get: () => this.recipesInBasket,
    set: (recipesInBasket) =>  {
      if (recipesInBasket?.action) {        
        this.setRecipesInBasket(recipesInBasket);
      } else {
        this.recipesInBasket = recipesInBasket
      }
    }
  });
  
  //USER MODULE SETUP
  Object.defineProperty(this.userModule, 'currencyIcon', {
    configurable: true,
    enumerable: true,
    get: () => this.currencyIcon,
  });
  Object.defineProperty(this.userModule, 'status', {
    get: () => this.status,
    set: (status) => { this.status = status },
  });
  Object.defineProperty(this.userModule, 'pseudo', {
    get: () => this.pseudo,
    set: (pseudo) => { this.pseudo = pseudo },
  });
  Object.defineProperty(this.userModule, 'socialCode', {
    get: () => this.socialCode,
    set: (socialCode) => { this.socialCode = socialCode },
  });

  return {
    shoppingListModule: this.shoppingListModule,
    userModule: this.userModule,
  }
}

});
</script>