import axios from "axios";
const baseUrl = process.env.VUE_APP_API_URL + "api";
console.log('BASE URL', baseUrl);

let headers =  {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

const create = (subUrl, newObject) => {
  return axios
    .post(baseUrl + subUrl, newObject, { headers: {...headers, 
      'Authorization': `Bearer ${localStorage.getItem("token")}` }})
    .then((response) => {
      return response.data;
    });
};
const createWithNoToken = (subUrl, newObject) => {
  return axios.post(baseUrl + subUrl, newObject).then((response) => {
    return response.data;
  });
};
const getAll = (subUrl) => {
  return axios
    .get(baseUrl + subUrl, { headers: {...headers, 
      'Authorization': `Bearer ${localStorage.getItem("token")}` }})
    .then((response) => {
      return response.data;
    });
};

const getAllWithNoToken = (subUrl) => {
  return axios.get(baseUrl + subUrl).then((response) => {
    return response.data;
  });
};

const getByParams = async (subUrl, params) => {
  return axios.get(baseUrl + subUrl, { headers: {...headers, 
    'Authorization': `Bearer ${localStorage.getItem("token")}` }, params: params}).then((response) => {
    return response.data;
  });
};

const getByParamsNoToken = async (subUrl, params) => {
  return axios.get(baseUrl + subUrl, {params: params}).then((response) => {
    return response.data;
  });
};

const get = async (subUrl) => {
  return axios.get(baseUrl + subUrl, { headers: {...headers, 
    'Authorization': `Bearer ${localStorage.getItem("token")}` }}).then((response) => {
    return response.data;
  });
};

const patch = async (subUrl, params) => {
  return axios
    .patch(baseUrl + subUrl, params, { headers: {...headers, 
      'Authorization': `Bearer ${localStorage.getItem("token")}` }})
    .then((response) => {
      return response.data;
    });
};

const handleDelete = async (subUrl) => {
  return axios
    .delete(baseUrl + subUrl, { headers: {...headers, 
      'Authorization': `Bearer ${localStorage.getItem("token")}` }})
    .then((response) => {
      return response.data;
    });
};

export {
  create,
  getAll,
  getByParams,
  patch,
  handleDelete,
  getByParamsNoToken,
  createWithNoToken,
  getAllWithNoToken,
  get,
};
