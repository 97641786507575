<script>
import { defineComponent } from 'vue';

const httpService = require("@/utils/services");

export default defineComponent({
  name: 'UserModule',
  data() {
    return {
      userModule: {},
      currencyIcon: null,
      userInfosLoaded: false,
      status: null,
      pseudo: null,
      socialCode: null
    }
  },

  watch: { 
  },

  methods: {   
    async reloadUserSetups() {
      await this.getCurrency();
      await this.getUserInfos();
    },

    getCurrency() {
      if (this.currencyIcon) { return this.currencyIcon }
      httpService.get("/currency/byUser").then((res) => {
        return this.currencyIcon = res.icon;
      }).catch(error => console.error(error));
    },

    getUserInfos() {
      if (this.userInfosLoaded) { return; }
      httpService.getByParams("/auth/user/", { languageCode: this.languageCode }).then((res) => {
        this.status = res.status;
        this.pseudo = res.pseudo;
        this.socialCode = res.socialCode;
      }).catch(error => console.error(error));
    }
    
  }
});
</script>
