const checkRequired = (value, errmsg) => {
  if (!value) {
    return errmsg;
  }
  return "";
};

const checkRequiredArray = (valuesArray, errmsg) => {
  if (!valuesArray) {
    return errmsg;
  }
  valuesArray.forEach(value => {
    if (!value || value.length <= 0) {
      return errmsg
    }
  });
  return "";
};

const checkPattern = (value, pattern, errmsg) => {
  if (!pattern.test(value)) {
    return errmsg;
  }
  return "";
};

const checkRequiredAndPattern = (value, pattern, errmsgRequired, errmsgPattern) => {
  if (!value) {
    return errmsgRequired;
  }
  if (!pattern.test(value)) {
    return errmsgPattern;
  }
  return "";
};

const checkPositive = (value, canBeZero, errmsg) => {
  if ((!canBeZero && value > 0) || (canBeZero || value >= 0)) {
    return "";
  }
  return errmsg;
};

const checkPositiveAndRequired = (value, canBeZero, errmsgRequired, errmsgPositive) => {
  if (!value && value !== 0) {
    return errmsgRequired;
  }
  if ((!canBeZero && value > 0) || (canBeZero && value >= 0)) {
    return "";
  }
  return errmsgPositive;
};

export {
  checkRequired,
  checkPattern,
  checkRequiredAndPattern,
  checkPositive,
  checkPositiveAndRequired,
  checkRequiredArray
};