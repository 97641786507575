import { createRouter, createWebHistory } from "@ionic/vue-router";
import Tabs from "../views/Tabs.vue";
import Login from "../views/Login/Login.vue";
import Inscription from "../views/Inscription/Inscription.vue";
import Language from "../views/Language/Languages.vue";

const languageIsSelected = () => localStorage.getItem("language") != null;
const isLoggedIn = () => localStorage.getItem("token") != null;

const loggedStatusCheck = (to, from, next) => {
  if (isLoggedIn()) {
    next({
      path: '/home',
    });
  } else if (languageIsSelected()) {
    next({
      path: '/login',
    });
  } else {
    next({
      path: '/language',
    });
  }
}
const loggedInCheck = (to, from, next) => {
  if (isLoggedIn() && languageIsSelected()) {
    next();
  } else {
    loggedStatusCheck(to, from, next);
  }
}
const loggedOutCheck = (to, from, next) => {
  if (!isLoggedIn() && (languageIsSelected() || to.path === '/language')) {
    next();
  } else {
    loggedStatusCheck(to, from, next);
  }
}

const routes = [
  {
    path: "/",
    beforeEnter: loggedStatusCheck,
  },
  {
    path: "/language",
    name: "Language",
    component: Language,
    beforeEnter: loggedOutCheck,
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: loggedOutCheck,
  },
  {
    path: "/inscription",
    name: "Inscription",
    component: Inscription,
    beforeEnter: loggedOutCheck,
  },


  {
    path: "/home",
    name: "Tabs",
    component: Tabs,
    beforeEnter: loggedInCheck,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/categoryBudgetDetails/:name/:totalBudget/:icon/:month/:year",
        name: "CategoryBudgetDetails",
        component: () => import("@/views/Categories/CategoryBudgetDetails.vue"),
      },
      {
        path: "/categories/:month/:year",
        name: "Categories",
        component: () => import("@/views/Categories/Categories.vue"),
      },
   
      {
        path: "/lifeprojects",
        name: "LifeProjects",
        component: () => import("@/views/Projects/LifeProjects.vue"),
      },
      {
        path: "/projectDetails/:id",
        name: "ProjectDetails",
        component: () => import("@/views/Projects/ProjectDetails.vue"),
      },
      {
        path: "/categories/management",
        name: "CategoriesManagement",
        component: () => import("@/views/Categories/CategoriesManagement.vue"),
      },
      {
        path: "/friendship",
        name: "Friendship",
        component: () => import("@/views/Friendship/Friendship.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/views/Settings/Settings.vue"),
      },
      {
        path: "/budgetCourse",
        name: "BudgetCourse",
        component: () => import("@/views/Budget/CourseBudget.vue"),
      },
      {
        path: "/recurringPaiements",
        name: "RecurringPaiements",
        component: () => import("@/views/RecurringPaiements/RecurringPaiements.vue"),
      },
      {
        path: "/transactions/:navigateTab?",
        name: "Transactions",
        component: () => import("@/views/Transactions/Transactions.vue"),
        props: true,
        alias: ['/newTransaction', '/allTransactions']
      },
      {
        path: "/basket",
        name: "basket",
        component: () => import("@/views/Basket/Basket.vue"),
      },
      {
        path: "/budget",
        name: "Budget",
        component: () => import("@/views/Categories/CategoriesManagement.vue"),
      },
      {
        path: "/recipes",
        name: "Recipes",
        component: () => import("@/views/Recipes/Recipes.vue"),
      },
      {
        path: "/shoppinglists",
        name: "Shopping Lists",
        component: () => import("@/views/ShoppingLists/ShoppingLists.vue"),
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
