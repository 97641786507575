<template>
  <ion-page>
    <ion-header>     
      <TopToolbar :title="pageTitle" />
    </ion-header>

    <ion-content :fullscreen="true">
          <ion-content>
            <ion-button @click="$router.go(-1)" fill="clear" color="medium" class="ion-float-right">
              <font-awesome-icon :icon="['fas', 'times']" size="2x" />
            </ion-button>
            <SignUpForm :ionViewLifeCycleStage='ionViewLifeCycleStage' @formSubmit='onFormSubmit'/>
          </ion-content>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

// import { Plugins } from "@capacitor/core";
// const { PushNotifications } = Plugins;

import {
  IonContent, IonPage, IonButton, IonHeader
} from "@ionic/vue";

import SignUpForm from './SignUpForm.vue';
import TopToolbar from '@/components/Navigation/TopToolbar.vue';

var inscription = defineComponent({
name: "inscription",
components: { IonContent, IonPage, IonHeader, IonButton,

SignUpForm, TopToolbar },
inject: [ 'translationsData' ],

computed: {    
  pageTitle() {
    console.log(this.translationsData?.signUpTranslation?.signup);
    return this.translationsData?.signUpTranslation?.signup;
  },
},

ionViewWillEnter() {
  this.ionViewLifeCycleStage = 'ionViewWillEnter';

  // this.pushInit();
},
ionViewWillLeave() {
  this.ionViewLifeCycleStage = 'ionViewWillLeave';
},
ionViewDidLeave() {
  this.ionViewLifeCycleStage = 'ionViewDidLeave';
},
data() {
  return {
    router: useRouter(),
    ionViewLifeCycleStage: '',
  };
},

methods: {
  onFormSubmit() {      
    this.router.push("/home");
  }
},
});

export default inscription;
</script>

<style>
ion-slides {
  --bullet-background: rgb(129, 129, 129);
  --bullet-background-active: rgb(255, 177, 59);
  height: 100%;
}

ion-icon {
  width: 50px !important;
  height: 50px !important;
}

.input-container {
  margin-top: +5%;
}
</style>
