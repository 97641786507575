<template>
  <ion-page>
    <ion-content class="background-image" no-scroll padding>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, pickerController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
const httpService = require("../../utils/services");

export default defineComponent({
  name: "Language",
  inject: [ 'setLanguageCode' ],
  components: {
    IonContent,
    IonPage,
  },
  ionViewDidEnter() {
    httpService
      .getAllWithNoToken("/language/")
      .then((response) => {
        Object.keys(response).forEach((key) => {
          let element = response[key];
          this.languages.push(element.languageName);
          this.languagesCode.push(element.languageCode);
        });

        this.openPicker();
      })
      .catch((error) => {
        console.log("HTTP SERVICE ERROR language retrieving :", error);
      });
  },
  ionViewWillLeave() {
    this.picker.dismiss();
  },
  ionViewDidLeave() {
    this.languages.length = 0;
    this.languagesCode.length = 0;
  },
  data() {
    return {
      languages: [],
      languagesCode: [],
      isLanguageChoose: false,
      currentLanguage: "",
      currentIndex: null,
      picker: null,
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    async openPicker() {
      this.picker = await pickerController.create({
        columns: this.getColumns(1, this.languages.length, this.languages),
        backdropDismiss: false,
        buttons: [
          {
            text: "Confirm",
            handler: async (value) => {
              this.setLanguageCode(this.languagesCode[value.col1.value]);
              this.$router.replace("/login");
            },
          },
        ],
      });

      await this.picker.present();
    },

    getColumns(numColumns, numOptions, columnOptions) {
      return [
        {
          name: `col${1}`,
          options: this.getColumnOptions(1, numOptions, columnOptions),
        },
      ];
    },
    getColumnOptions(columnIndex, numOptions, columnOptions) {
      let options = [];
      for (let i = 0; i < numOptions; i++) {
        options.push({
          text: columnOptions[i],
          value: i,
        });
      }

      return options;
    },
    concat(country) {
      return "flag-icon flag-icon-" + country;
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.flag-icon {
  width: 90px;
  height: 90px;
  margin: 10px;
}
.hide {
  display: none;
}
.button-style {
  visibility: inherit;
  text-align: center !important;
  margin-left: 40%;
}
.card-style {
  margin-top: +50%;
  border-radius: 6%;
  width: 90%;
  margin-left: +5%;
}

.card-title {
  text-align: center;
}

.itemClicked {
  background-color: rgb(172, 158, 158);
  opacity: 0.7;
}

.itemUnclicked {
  background-color: white;
}

.background-image {
  --background: url("../../../public/assets/icon/home.jpeg") 0 0/100% 100%
    no-repeat;
}

#test {
  color: white;
}
</style>
