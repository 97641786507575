<template>
  <ion-item mode="md" :disabled="disabled">
    <ion-label>{{ inputLabel }}</ion-label>
    <ion-select v-bind="$attrs" :name="name" :interface="selectInterface" :multiple="multiple"
    :value="modelValue" @ionChange="$emit('update:modelValue', $event.target.value)" :interface-options="customPopoverOptions">
      <slot name="defaultOption"></slot>
      <slot name="selectItems"><ion-select-option
        v-for="(item, index) in items" :key="index" :value="item">
        {{ item }}
      </ion-select-option></slot>
    </ion-select>
  </ion-item>
  <div v-if="displayError" class="error-style">{{ errorMessage }}</div>
</template>
<script>
import { defineComponent } from 'vue';

import { IonLabel, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';

export default defineComponent ({
name: 'BaseSelect',
components: { IonLabel, IonItem, IonSelect, IonSelectOption },
emits: ['update:modelValue'],
props: {
  modelValue: [String, Array],
  name: String,
  inputLabel: {
    type: String,
    default: "No label error"
  },
  items: {
    type: [Array, undefined],
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: "Undefined Error"
  },
  selectInterface: {
    type: [String, undefined],
    default: 'alert'
  },
  disabled: {
    type: [Boolean, undefined],
    default: false
  }
},
data() {
  return {
    selectedValue: null,
    displayError: false,
  }
},
watch: {
  errorMessage(val) {
    if (val.length > 0) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }
},
computed: {
  customPopoverOptions() {
    if (this.items && this.selectInterface && this.selectInterface === 'popover' && this.items.length >= 7) {
      return { cssClass: "select-popover-position", translucent: true }
    }
    return undefined;
  }
}
  
});
</script>