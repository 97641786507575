<template>
  <ion-item mode="md" v-bind="$attrs" :disabled="disabled" :color="backgroundColor" lines="none" :button="button">
    <slot name="label">
      <ion-label :position="position" :class="labelClassBigger">{{ inputLabel }}</ion-label>
    </slot>
    <slot name="beforeInput" />
    <slot name="input">
      <ion-input :name="name" :type="inputType" :step="step" :inputmode="inputType" clearOnEdit="false" :color="color"
      :value="modelValue||value" @ionChange="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder" :dataBefore="dataBefore" :class="inputClass"></ion-input>
    </slot>
  </ion-item>
  <div v-if="displayError" class="error-style">{{ errorMessage }}</div>
</template>

<script>
import { defineComponent } from 'vue';

import { IonLabel, IonInput, IonItem } from '@ionic/vue';

export default defineComponent({  
name: 'BaseInput',
components: { IonLabel, IonInput, IonItem },
emits: ['update:modelValue'],
props: {
  modelValue: {
    type: [ String, Number, undefined ],
    required: false
  },
  value: {
    type: [ String, undefined ],
    required: false
  },
  name: String,
  inputType: {
    type: String,
    default: 'text'
  },
  step: {
    type: [String, undefined],
    required: false
  },
  dataBefore: {
    type: [String, undefined],
    required: false
  },
  inputLabel: {
    type: String,
    default: "No label error"
  },
  placeholder: {
    type: [String, undefined],
    default: ""
  },
  errorMessage: {
    type: String,
    default: "Undefined Error"
  },
  disabled: {
    type: [Boolean, undefined],
    required: false
  },
  color: {
    type: [String, undefined],
    default: 'dark'
  },
  backgroundColor: {
    type: [String, undefined],
    required: false
  },
  labelIsBigger: {
    type: [Boolean, undefined],
    default: false
  },
  position: {
    type: [String, undefined],
    default: "floating"
  },
  button: {
    type: [Boolean, undefined],
    default: false
  }
},
data() {
  return {
    displayError: false,
  }
},
watch: {
  errorMessage(val) {
    if (val.length > 0) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }
},
computed: {
  labelClassBigger() {
    return {
      'bigger-label': this.labelIsBigger       
    }
  },

  inputClass() {
    if (this.dataBefore) {
      return 'money-data';
    }
    return '';
  }
}

});
</script>

<style scoped>
.bigger-label {
  font-size: 1.5rem;
}
</style>