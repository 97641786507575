<template>
  <ion-menu swipeGesture="false" side="start" type="overlay" menu-id="first" content-id="main" class="my-custom-menu">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
        <ion-buttons slot="end" class="ion-margin-right">
            <ion-button @click="handleClickMenuClose" fill="clear" class="ion-margin-right">
              <font-awesome-icon :icon="['fas', 'arrow-left']" size="2x" />
            </ion-button>          
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>  

        <ion-menu-toggle v-for="page in nav_pages" :key="page.name" auto-hide="false" >      
          <ion-item @click="handleClickPageChange(page)" :id="page.name" button
          :class="selectedPage === page.name ? 'active-page' : null">            
            <font-awesome-icon :icon="['fas', page.icon]" />
            <ion-label class="ion-margin-start">{{ page.label }}</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <div class="menu-bottom-border" />

        
        <ion-menu-toggle v-for="page in acc_pages" :key="page.name" auto-hide="false" >      
          <ion-item :router-link="page.url" :id="page.name" button>            
            <font-awesome-icon :icon="['fas', page.icon]" />
            <ion-label class="ion-margin-start">{{ page.label }}</ion-label>
          </ion-item>
        </ion-menu-toggle>

      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent } from 'vue';

import Pages from '@/mixins/Pages.vue';

import { IonContent, IonHeader, IonItem, IonList, 
  IonButtons, IonButton, IonLabel, IonMenuToggle,
  IonMenu, IonTitle, IonToolbar, menuController
} from '@ionic/vue';

export default defineComponent({
  name:'Menu',
  components: { IonContent, IonHeader, IonItem, 
    IonButtons, IonButton, IonLabel, IonMenuToggle,
    IonList, IonMenu, IonTitle, IonToolbar, 
  },
  mixins: [ Pages ],
  methods: {
    handleClickMenuClose() {
      menuController.close();
    }
  }
});
</script>

<style scoped>
.my-custom-menu {
  --width: 400px;
}

.menu-bottom-border {
	border-bottom: solid 2px;
}

.active-page, ion-item.active-page:hover * {
  color: var(--ion-color-primary) !important;
}
</style>