<template>
  <ion-page v-if="translations">
    <div class="short-content transparent-card">
      <h1 class="title">{{ translations.connection }}</h1>
      <form @submit.prevent="loginHandler" id="signinform">
        <ion-grid>

          <ion-row class="form-content-align">
            <ion-col class="input-bottom-border">
              <BaseInput name="email" v-model="formData.email" inputClass="signin-text-color"
              :inputLabel="translations.email" :errorMessage="errors.emailError"/>
            </ion-col>
          </ion-row>

          <ion-row class="form-content-align">
            <ion-col class="input-bottom-border">
              <BaseInput name="password" v-model="formData.password" inputType="password"
              :inputLabel="translations.password" :errorMessage="errors.passwordError"/>
            </ion-col>
          </ion-row>

          <ion-row class="form-content-align">
            <ion-col>
              <BaseButton type="submit" :buttonLabel="translations.login" expand="block" />
            </ion-col>
          </ion-row>

          <ion-row class="form-content-align">
            <ion-col class="ion-text-center">
              <ion-label color="dark" routerLink="/inscription" class="is-a-link">{{ translations.newAccount }}</ion-label>
            </ion-col>
          </ion-row>

        </ion-grid>
      </form>      
    </div>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';

import { IonLabel, IonPage, IonGrid, IonRow, IonCol } from '@ionic/vue';

import BaseInput from '../../components/Form/BaseInput.vue';
import BaseButton from '../../components/Form/BaseButton.vue';

import ToastDisplay from '../../mixins/ToastDisplay.vue';

const httpService = require("../../utils/services");
const utils = require("../../utils/utils");

export default defineComponent({  
name: "SignInForm",
components: { IonLabel, IonPage, IonGrid, IonRow, IonCol,

  BaseInput, BaseButton,
},
mixins: [ToastDisplay],
inject: [ 'translationsData', 'setIsLoggedIn', 'getLanguageCode' ],
emits: ['formSubmit'],
props: ['ionViewLifeCycleStage'],
data() {
  return {   
    formData: {
      email: null,
      password: null,
      preferredLanguage: this.getLanguageCode() || 'gb',
      token: null,
    },
    errors: {
      emailError: "",
      passwordError: "",
    },
  };
},
watch: {
  ionViewLifeCycleStage(val) {
    if (typeof this[val] === "function") { 
      this[val]();
    }
  }
},
computed: {
  translations() {
    return this.translationsData.loginTranslation;
  }
},

methods: {  
  ionViewWillLeave() {
    this.dissmissAllViewToasts();
  },

  ionViewDidLeave() {
    this.clearForm();
  },

  clearForm() {             
    Object.keys(this.formData).forEach(key => {
      if (typeof this.formData[key] === 'boolean') this.formData[key] = false;
      else if (Array.isArray(this.formData[key])) this.formData[key] = [];     
      else if (typeof this.formData[key] === 'string') this.formData[key] = "";   
      else this.formData[key] = null;
    });

    Object.keys(this.errors).forEach(key => {
      this.errors[key] = "";
    });
  },

  async loginHandler() {   
    
    this.formData.preferredLanguage = this.getLanguageCode();

    this.formData.email = this.formData.email?.toLowerCase();

    if (!this.formIsValid(this.formData)) {
      return;
    }  

    if (!this.formData.token) {
      this.formData.token = "no token";
    }

    await httpService
      .createWithNoToken("/auth/login/", this.formData)
      .then((token) => {
        this.setIsLoggedIn(token.token);
        this.queueSuccessToast(this.translations.successfulConnection);
        this.$emit('formSubmit');
      })
      .catch((error) => {
        this.queueErrorToast(error.response.data.error);
      });
  },

  formIsValid(formData) {
    this.errors.emailError = utils.checkRequired(formData.email, this.translations.emailRequired);
    this.errors.passwordError = utils.checkRequired(formData.password, this.translations.passwordRequired);

    const formIsValid = Object.values(this.errors)
    .filter(err => typeof err === 'undefined' || err.length > 0 ).length === 0;

    return formIsValid;
  },
}
    
});
</script>

<style scoped>
:deep(.item.sc-ion-label-md-h), 
:deep(.item .sc-ion-label-md-h) {
  --color: black;
}
.title {
  color: black;
}
</style>
