<template>
  <ion-app>   
    <ApplicationSettings>
      <Menu />
      <ion-router-outlet id="main" />
    </ApplicationSettings>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet} from "@ionic/vue";
import { defineComponent } from "vue";
import Menu from './views/Menu.vue';
import ApplicationSettings from '@/components/ApplicationSettings.vue';

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    Menu,
    ApplicationSettings
  },

  async created(){
  },

  methods:{
  }
});
</script>

<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(../public/fonts/Roboto-Regular.ttf) format("truetype");
}
</style>
