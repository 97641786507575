<template>
  <ion-page>
    <ion-header>     
      <TopToolbar :title="pageTitle" />
    </ion-header>

    <ion-content>
      <ion-content class="background-image" no-scroll padding> </ion-content>
      <SignInForm :ionViewLifeCycleStage='ionViewLifeCycleStage' @formSubmit='onFormSubmit' />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonHeader } from "@ionic/vue";

import SignInForm from './SignInForm.vue';
import TopToolbar from '@/components/Navigation/TopToolbar.vue';

import { useRouter } from "vue-router";
import { defineComponent } from "vue";

var loginComponent = defineComponent({  
name: "login",
components: { IonContent, IonPage, IonHeader, SignInForm, TopToolbar },
inject: [ 'translationsData' ],

computed: {    
  pageTitle() {
    return this.translationsData?.loginTranslation?.connection;
  },
},

ionViewWillEnter() {
  this.ionViewLifeCycleStage = 'ionViewWillEnter';

  // this.pushInit();
},
ionViewWillLeave() {
  this.ionViewLifeCycleStage = 'ionViewWillLeave';
},
ionViewDidLeave() {
  this.ionViewLifeCycleStage = 'ionViewDidLeave';
},

data() {
  return {
    router: useRouter(),
    ionViewLifeCycleStage: ''
  };
},

methods: {
  onFormSubmit() {      
    this.$router.push("/home");
  }
},
});
export default loginComponent;
</script>

<style scoped>
.scroll-content {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.container-login {
  text-align: center;
  bottom: +140px;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.container-inscription {
  text-align: center;
  bottom: +70px;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.item.item-input {
  border: none;
  margin-left: +5%;
  width: 90%;
}

.background-image {
  --background: url("../../../public/assets/icon/home.jpeg") 0 0/100% 100%
    no-repeat;
}

.modal-style {
  background-color: green;
  height: 50%;
}
.show {
  height: 50%;
  width: 90%;
  margin-left: +5%;
  transform: translateY(-140%);
  margin-bottom: -150%;
  padding-bottom: 300px !important;

  background: rgba(255, 255, 255, 0.5);
}

.card-title {
  text-align: center;
}
.card-button {
  text-align: center;
  padding: 10px;
}

.item-style {
  background: rgba(255, 255, 255, 0.5);
}
ion-item {
  --background: transparent !important;
  --border-color: transparent !important;
}

.text-center {
  width: 100%;
  text-align: center;
}

.underline {
  text-decoration: underline;
}
</style>
