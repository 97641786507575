<template>

    <form @submit.prevent="registerHandler($event)" id="signupform" v-if="pageIsLoaded && translations">
    <h1 class="title">{{ translations.signup }}</h1>

    <ion-grid class="form-wrapper">

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border twin-col">
          <BaseInput name="firstName" v-model="formData.firstName"
          :inputLabel="translations.firstname" :errorMessage="errors.firstNameError"/>
        </ion-col>
        <ion-col class="input-bottom-border twin-col">
          <BaseInput name="lastName" v-model="formData.lastName"
          :inputLabel="translations.lastname" :errorMessage="errors.lastNameError"/>
        </ion-col>
      </ion-row>
      
      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border">
          <BaseInput name="email" v-model="formData.email"
          :inputLabel="translations.email" :errorMessage="errors.emailError"/>  
        </ion-col>    
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border twin-col">
          <BaseInput name="password" v-model="formData.password"
          :inputLabel="translations.password" inputType="password"
          :errorMessage="errors.passwordError"/>
        </ion-col>
        <ion-col class="input-bottom-border twin-col">
          <BaseInput name="passwordRepeat" v-model="formData.passwordRepeat"
          :inputLabel="translations.passwordRepeat" inputType="password"
          :errorMessage="errors.passwordRepeatError"/>
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border">
          <BaseSelect name="preferedCurrency" v-model="formData.preferedCurrency"
          :inputLabel="translations.currency" :items="currenciesArray"
          :errorMessage="errors.preferedCurrencyError" />
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border">
          <BaseSelect name="accounts" v-model="formData.accounts"
          :inputLabel="translations.accountLabel" :items="accountsArray" :multiple="true" />
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border">
          <BaseSelect name="status" v-model="formData.status"
          :inputLabel="translations.status" :items="statusArray"
          :errorMessage="errors.statusError" />
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="input-bottom-border">
          <BaseSelect name="subStatus" v-model="formData.subStatus"
          :inputLabel="translations.subStatusLabel" :items="subStatusArray" :multiple="true" />
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col>
          <BaseCheckbox name="generalConsent" v-model="formData.generalConsent"
          :inputLabel="translations.terms" :errorMessage="errors.generalConsentError"/>
        </ion-col>
      </ion-row>

      <ion-row class="form-content-align">
        <ion-col class="ion-text-center">
          <BaseButton type="submit" :buttonLabel="translations.SignUpNow" shape="round" size="large" />
        </ion-col>
      </ion-row>

    </ion-grid>
  </form>
</template>

<script>
import { defineComponent } from 'vue';

import { IonGrid, IonRow, IonCol } from '@ionic/vue';

import BaseSelect from '../../components/Form/BaseSelect.vue';
import BaseInput from '../../components/Form/BaseInput.vue';
import BaseCheckbox from '../../components/Form/BaseCheckbox.vue';
import BaseButton from '../../components/Form/BaseButton.vue';

import ToastDisplay from '../../mixins/ToastDisplay.vue';

const httpService = require("../../utils/services");
const utils = require("../../utils/utils");

export default defineComponent ({
name: "SignUpForm",
components: { IonGrid, IonRow, IonCol,
  
  BaseSelect, BaseInput, BaseCheckbox, BaseButton },
inject: [ 'translationsData', 'setIsLoggedIn', 'getLanguageCode' ],
mixins: [ToastDisplay],
emits: ['formSubmit'],
props: ['ionViewLifeCycleStage'],
data() {
  return {
    pageIsLoaded: false,
    subStatus: [],
    accounts: [],
    currencies: [],
    status: {
      label: "",
      translations: {}
    },
    formData: {
      firstName: null,
      lastName: null,
      password: null,
      passwordRepeat: null,
      email: null,
      preferedCurrency: null,
      generalConsent: false,
      accounts: [],
      subStatus: [],
      //The following exists in Database, but are not implemented in the 
      //frontend. So, default values are used instead.
      preferedLanguage: localStorage.getItem("language"),
      ipAddress: "127.0.0.1",
      deviceToken: "no token",
      gdprConsent: true,
      cookiesConsent: false,
    },
    errors: {
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      passwordRepeatError: "",
      emailError: "",
      preferedCurrencyError: "",
      generalConsentError: "",
      statusError: "",
    }
  };
},
watch: {
  ionViewLifeCycleStage(val) {
    if (typeof this[val] === "function") { 
      this[val]();
    }
  }
},
computed: {
  translations() {
    return this.translationsData.signUpTranslation;
  }, 
  currenciesArray() {
    return this.currencies.map(currency => currency.name);
  },
  subStatusArray() {
    return this.subStatus.map(status => status.name);
  },
  accountsArray() {
    return this.accounts.map(account => account.name);
  },
  statusArray() {
    return Object.values(this.status.translations);
  },
},

beforeMount() {
  this.ionViewWillEnter();
  this.pageIsLoaded = true;
},

methods: {
  ionViewWillEnter() {
    httpService
      .getAllWithNoToken(
        "/status/subStatus/" + localStorage.getItem("language")
      )
      .then((response) => {
        this.subStatus = response;
      })
      .catch((error) => console.error(error));

    httpService
      .getAllWithNoToken("/currency/")
      .then((response) => {
        this.currencies = response;
      })
      .catch((error) => console.error(error));

    httpService
      httpService.getByParamsNoToken("/status/", { languageCode: this.getLanguageCode() }).then(response => {
        this.status.label = response.label;
        this.status.translations = { ...response };
        delete this.status.translations['label'];
      }).catch((error) => console.error(error));

    httpService
      .getAllWithNoToken("/account/default")
      .then((response) => {
        this.accounts = response;
      })
      .catch((error) => console.error(error));
  },  

  ionViewWillLeave() {
    this.dissmissAllViewToasts();
  },

  ionViewDidLeave() {
    this.clearForm();
  },

  clearForm() {             
    Object.keys(this.formData).forEach(key => {
      if (typeof this.formData[key] === 'boolean') this.formData[key] = false;
      else if (Array.isArray(this.formData[key])) this.formData[key] = [];     
      else if (typeof this.formData[key] === 'string') this.formData[key] = "";   
      else this.formData[key] = null;
    });

    Object.keys(this.errors).forEach(key => {
      this.errors[key] = "";
    });
  },

  registerHandler() {       
    if (!this.formIsValid(this.formData)) {
      return;
    }    

    //The following exists in Database, but are not implemented in the 
    //frontend. So, default values are used instead.
    this.formData.preferedLanguage = localStorage.getItem("language");
    this.formData.ipAddress = "127.0.0.1";
    this.formData.deviceToken = "no token";
    this.formData.gdprConsent = true;
    this.formData.cookiesConsent = false;
    
    this.formData.subStatus = this.formData.subStatus.map(key => {
      const obj = this.subStatus.find(subStatus => subStatus['name'] === key);
      return obj['id'];
    });

    this.formData.status = 
      Object.keys(this.status.translations).find(key => this.status.translations[key] === this.formData.status);

    this.formData.email = this.formData.email?.toLowerCase();

    httpService
      .createWithNoToken("/auth/register", this.formData)
      .then((token) => {
        this.setIsLoggedIn(token.token);
        this.queueSuccessToast(this.translations.successfulRegistration);
        this.$emit('formSubmit');
      })

      .catch((error) => {
        console.error(error);
        this.queueErrorToast(error.response.data.error);
      });
  },

  formIsValid(formData) {
    this.errors.firstNameError = utils.checkRequired(formData.firstName, this.translations.firstnameRequired);

    this.errors.lastNameError = utils.checkRequired(formData.lastName, this.translations.lastNameRequired);

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.errors.emailError = utils.checkRequiredAndPattern(formData.email, emailRegex, 
    this.translations.emailRequired, this.translations.emailPattern);

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    this.errors.passwordError = utils.checkRequiredAndPattern(formData.password, passwordRegex, 
    this.translations.passwordRequired, this.translations.passwordPattern);

    this.errors.passwordRepeatError = utils.checkRequiredAndPattern(formData.passwordRepeat, new RegExp(formData.password), 
    this.translations.passwordTwice, this.translations.passwordTwice);

    this.errors.preferedCurrencyError = utils.checkRequired(formData.preferedCurrency, this.translations.currencyRequired);

    this.errors.statusError = utils.checkRequired(formData.status, this.translations.statusRequired);

    this.errors.generalConsentError = utils.checkRequired(formData.generalConsent, this.translations.termsRequired);

    const formIsValid = Object.values(this.errors)
    .filter(err => typeof err === 'undefined' || err.length > 0 ).length === 0;

    return formIsValid;
  },
},

});
</script>

<style scoped>
.title {
  font-size: +35px;
}
</style>
