<template>
  <ion-item mode="md" v-bind="$attrs" :disabled="disabled">
    <ion-checkbox color="secondary" :name="name"
    :checked="modelValue" @ionChange="$emit('update:modelValue', $event.target.checked)"></ion-checkbox>
    <ion-label>&nbsp; {{ inputLabel }}</ion-label>
  </ion-item>
  <div v-if="displayError" class="error-style">{{ errorMessage }}</div>
</template>

<script>
import { defineComponent } from 'vue';

import { IonLabel, IonItem, IonCheckbox } from '@ionic/vue';

export default defineComponent({
name: 'BaseCheckbox',
components: { IonLabel, IonItem, IonCheckbox },
emits: ['update:modelValue'],
props: {
  modelValue: [Boolean, String],
  name: String,
  inputLabel: {
    type: String,
    default: "No label error"
  },
  disabled: {
    type: [Boolean, undefined],
    required: false
  },
  errorMessage: {
    type: String,
    default: "Undefined Error"
  },
},
data() {
  return {
    displayError: false,
  }
},
watch: {
  errorMessage(val) {
    if (val.length > 0) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }
},
});
</script>