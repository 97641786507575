<script>
import { toastController } from '@ionic/vue';
export default {
name: 'ToastDisplay',
data: () => ({
  toastShown: false,
  toast: null,
  toastQueue: [],
  queuedToast: null,
}),

watch: {
  toastShown() {
    this.showToast();
  },
  queuedToast() {
      if(this.queuedToast) {
          this.toastQueue.push(this.queuedToast);
          this.queuedToast = null;
          this.showToast();
      }
  }
},

methods: {
  async showToast() {
    if(!this.toastShown && this.toastQueue.length > 0) {
        this.toast = this.toastQueue.shift();
        // if(this.toast.color !== '') await this.$nextTick();
        this.toast.present();
        this.toastShown = true;
    }
  },
  dissmissAllViewToasts() {
    this.toastQueue = [];
    this.toast?.dismiss();
  },
  async queueToast(message, color, duration, buttons, position) {
    this.queuedToast = await toastController.create({
        message,
        color,
        duration,
        buttons,
        position
    });
    this.queuedToast.onDidDismiss().then(() => {
      this.toastShown = false;
      this.toast = null;
    }); 
  },
  queueSuccessToast(message) {
    this.queueToast(message, 'success', 2000, [{text: 'OK', role: 'cancel',}], 'top');
  },
  queueInfoToast(message) {
    this.queueToast(message, 'dark', 0,  [{text: 'OK', role: 'cancel',}], 'top');
  },
  queueWarningToast(message) {
    this.queueToast(message, 'warning', 0,  [{text: 'OK', role: 'cancel',}], 'top');
  },
  queueErrorToast(message) {
    this.queueToast(message, 'danger', 0,  [{text: 'OK', role: 'cancel',}], 'top');
  },
},
}
</script>