<template>
  <ion-page>
    <ion-tabs mode="md">
      <div class="navtab">

        <font-awesome-icon v-if="scrollStart" slot="start" :icon="['fas', 'angle-left']" class="fa-3x nav-arrows nav-arrow-start" />
        <font-awesome-icon v-if="scrollEnd" slot="end" :icon="['fas', 'angle-right']" class="fa-3x nav-arrows nav-arrow-end" />

        <ion-segment ref="navtab" scrollable :value="selectedPage" @scroll="onScroll($event.target)" @ionChange="onScroll($event.target)">

          <ion-segment-button v-for="page in nav_pages" :key="page.name" 
          :value="page.name" :href="page.url" @click="handleClickPageChange(page)">
            <font-awesome-icon :icon="['fas', page.icon]" class="fa-2x" />
            <ion-label stacked class="label-style">{{ page.label }}</ion-label>
          </ion-segment-button>

        </ion-segment>
      </div>
      
        <ion-router-outlet id="main" />
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabs,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import Pages from '@/mixins/Pages.vue';

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonSegment,
    IonSegmentButton,
    IonPage,
    IonRouterOutlet,
  },
  mixins: [ Pages ],

  data() {
    return {
      scrollStart: false,
      scrollEnd: false
    }
  },

  watch: {
  },

  computed: {
  },

  async mounted() {
    
    this.$nextTick(() => {
      this.onScroll(this.$refs.navtab.$el);
    });
    if (window.innerWidth < 890) { 
      this.scrollEnd = true;
    }
  },

  methods: {
    onScroll ({ scrollLeft, clientWidth, scrollWidth }) {      
      if (clientWidth >= 890) { 
        return;
      }
      if (scrollLeft + clientWidth >= scrollWidth-1 && clientWidth < scrollWidth) {
        this.scrollStart = true;
        this.scrollEnd = false;
      }
      else if (scrollLeft + clientWidth < scrollWidth && scrollLeft + clientWidth > clientWidth) {
        this.scrollStart = true;
        this.scrollEnd = true;
      }
      else if (scrollLeft === 0 && clientWidth < scrollWidth) {
        this.scrollStart = false;
        this.scrollEnd = true;
      }
    }
  }
};
</script>

<style scoped>
ion-label {
  font-size: 10pt;
}
.label-style {
  font-size: 10px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0px;
}
.button {
  width: 25%;
}

.segment-button-checked *  {
  color: var(--ion-color-primary) !important;
}

.nav-arrows {
  z-index: 1;
  position: absolute;
  color: var(--ion-color-dark);
  top: 0;
  height: 100%;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px !important; */
}
.nav-arrow-start {
  position: absolute;
  left: 1%;
}
.nav-arrow-end {
  position: absolute;
  right: 1%;
}
.navtab {
  position: relative;
  height: 3.6em;
}

ion-tabs {
  background: var(--ion-color-light) !important;
}
</style>